import Timer from "./timer";

$(document).ready(function () {
function gameInit() {
    let losePhrases = JSON.parse(Loose_phrases.loose_phrases);
    let slowPhrases = JSON.parse(Slow_phrases.slow_phrases);
    var g = {
        player: false,
        width: 0,
        height: 0,
        linesArray: [],
        isGameOver: true,
        isGameReady: true,
    };

    var game;

    var config = {
        type: Phaser.CANVAS,
        width: 2000,
        height: 1870,
        parent: 'map',
        transparent: true,
        physics: {
            default: 'arcade',
        },
        scene: {
            preload: preload,
            create: create,
            update: update
        }
    };

    window.onload = function () {
        game = new Phaser.Game(config);
    };
//    Flag animation
    function createFlagAnimation() {
        g.slices = [];
        var xsize = 2;
        var flag = g.this.textures.get('flag').getSourceImage();
        var frequency = 8.5;
        var amplitude = 3;
        for (var x = 0; x < Math.floor(flag.width / xsize); x++) {
            var segment = g.this.add.sprite(1703, 1410, 'flag');
            segment.setCrop(new Phaser.Geom.Rectangle(x * xsize, 0, xsize, flag.width));
            segment.y = segment.y + (amplitude * Math.sin(x * xsize / frequency));
            g.slices.push(segment);
        }
    }

    function updateFlagAnimation() {
        if (!g.frameDelay) {
            g.frameDelay = 2;
        }
        g.frameDelay--;
        if (!g.frameDelay) {
            for (var i = g.slices.length - 1; i >= 0; i--) {
                if (i === 0) {
                    g.slices[i].y = g.slices[g.slices.length - 1].y;
                } else {
                    g.slices[i].y = g.slices[i - 1].y;
                }
            }
        }
    }

    function createTooltipAnimation() {

        g.tooltip = g.this.add.image(300, 1030, 'tooltip');
        g.tooltip.scale = 2;

        g.this.tweens.add({
            targets: g.tooltip,
            y: { from: 1060, to: 1040 },
            duration: 1500,
            ease: 'easeOut',
            repeat: -1,
            yoyo: true

        });
    }

    function updateTooltipAnimation() {
        g.this.tweens.add({
            targets: g.tooltip,
            alpha: 0,
            duration: 200,
        });
    }
    function preload() {
        this.load.svg('map', '../wp-content/themes/new-triare-theme/images/take-a-rest-level-1.svg', {scale: 5});
        this.load.image('arrow1', '../wp-content/themes/new-triare-theme/images/arrow1.png');
        this.load.image('arrow2', '../wp-content/themes/new-triare-theme/images/arrow2.png');
        this.load.image('arrow3', '../wp-content/themes/new-triare-theme/images/arrow3.png');
        this.load.svg('flag', '../wp-content/themes/new-triare-theme/images/flag.svg');
        this.load.image('tooltip', '../wp-content/themes/new-triare-theme/images/tooltip.png');

        this.load.image('player', '../wp-content/themes/new-triare-theme/images/take-a-rest-car.png');
        this.load.json('mapLines', '../wp-content/themes/new-triare-theme/src/js/custom/take-a-rest-page/take-a-rest-level-1-coords.json');
    }

    function create() {
        g.this = this;
        g.debug = this.add.graphics();
        g.graphics = this.add.graphics();

        g.width = this.sys.game.canvas.width;
        g.height = this.sys.game.canvas.height;
        createFlagAnimation();
        // SET PARAMS
        g.spawn = {
            x: g.width * 0.17,
            y: g.height * 0.412,
        };

        g.pointer = {
            x: g.player.x + g.player.width / 2,
            y: g.player.y + g.player.height / 2,
        };

        // INIT TIMER
        g.timer = new Timer(document.getElementById('timer'));

        // INIT GAME
        initMap();
        createAnimations();

        g.target = new Phaser.Math.Vector2();
        g.player = this.physics.add.image(g.spawn.x, -100, 'player');
        g.player.setScale(1.5);

        g.playerTracker1 = this.add.rectangle(0, 0, 1, 1, 0xff00ff, 1);
        g.playerTracker2 = this.add.rectangle(0, 0, 1, 1, 0xff00ff, 1);

        this.physics.world.setBounds(0, 0, g.width, g.height);

        // START THE GAME HERE
        this.input.on('pointerdown', function (pointer) {
            if (!g.isGameOver) return;

            if (Phaser.Geom.Polygon.ContainsPoint(g.polygon, pointer)) {
                if (getDistanceToPlayer(pointer.x, pointer.y) < (g.width * 0.1) && g.isGameReady) {
                    g.isGameOver = false;
                    g.timer.startTimer();
                }
            }
        });

        // PROGRESS IN GAME
        //get global mouse position to prevent bug when you go out of canvas
        var canvasOffset = $("#map canvas").offset();
        var canvasScale = config.width / $("#map canvas").width();

        $("body").on("mousemove", function (event) {
            g.pointer.x = (event.pageX - canvasOffset.left) * canvasScale;
            g.pointer.y = (event.pageY - canvasOffset.top) * canvasScale;

            var x = g.pointer.x;
            var y = g.pointer.y;
            //g.debug.fillRect(x - 5, y - 5, 10, 10);
        });

        //Event for touch devices
        var xPos, yPos;

        $('body').on('touchstart', function (e) {
            xPos = e.originalEvent.touches[0].pageX;
            yPos = e.originalEvent.touches[0].pageY;

            g.pointer.x = (xPos - canvasOffset.left) * canvasScale;
            g.pointer.y = (yPos - canvasOffset.top) * canvasScale;
            // console.log(xPos, yPos)
        });

        $('body').on('touchmove', function (e) {
            xPos = e.originalEvent.touches[0].pageX;
            yPos = e.originalEvent.touches[0].pageY;

            g.pointer.x = (xPos - canvasOffset.left) * canvasScale;
            g.pointer.y = (yPos - canvasOffset.top) * canvasScale;
        });

        //update scale and
        $(window).on('resize', function () {
            canvasOffset = $("#map canvas").offset();
            canvasScale = config.width / $("#map canvas").width();
        });
    }

    function update() {
        updateFlagAnimation();

        if (g.isGameOver) {
            return;
        }

        g.timer.updateTimer();
        g.target.x = g.pointer.x;
        g.target.y = g.pointer.y;

        if (getDistanceToPlayer() > 15) {
            this.physics.moveToObject(g.player, g.target, getDistanceToPlayer());
            g.player.rotation = getAngle();
        }

        // CHECK WITH CAR BUMPERS
        updatePlayerTrackers();

        updateTooltipAnimation();
        var intersection = Phaser.Geom.Intersects.GetRectangleIntersection(g.finishLine, g.playerTracker1);

        if (intersection.x > g.finishLine.x && intersection.y > g.finishLine.y) {
            g.isGameOver = true;
            resetGame();
            winGame();
        }

        if (!Phaser.Geom.Polygon.ContainsPoint(g.polygon, g.playerTracker1)
            || !Phaser.Geom.Polygon.ContainsPoint(g.polygon, g.playerTracker2)
        ) {
            g.isGameOver = true;
            resetGame();
            gameOver();
        }

        //g.debug.clear().lineStyle(2, 0x00ff00);
        //g.debug.lineBetween(0, g.target.y, g.width, g.target.y);
        //g.debug.lineBetween(g.target.x, 0, g.target.x, g.height);

        if (g.player.body.speed > 0) {
            updatePlayerTrackers();
            updateTooltipAnimation();
            if (getDistanceToPlayer() < 4) {
                g.player.body.reset(g.target.x, g.target.y);
            }
        }
    }

    function createAnimations() {
        g.arrows = [];
        var coords = [
            {x: 408, y: 817},
            {x: 470, y: 850},
            {x: 530, y: 892},

            {x: 1093, y: 1074},
            {x: 1093, y: 1003},

            {x: 1650, y: 886}
        ];

        for (let i = 0; i < 6; i++) {
            let image;

            if (i < 3) {
                image = g.this.add.image(13, 24, 'arrow1');
            }
            if (i >= 3 && i < 5) {
                image = g.this.add.image(19, 25, 'arrow2');
            }
            if (i >= 5) {
                image = g.this.add.image(24, 16, 'arrow3');
            }

            image.x = coords[i].x;
            image.y = coords[i].y;
            image.scale = 2.7;
            image.delay = i * 100;
            image.alpha_direction = false;

            g.arrows.push(image);
        }

        for(let i = 0; i < g.arrows.length; i++) {
            g.this.tweens.add({
                targets: g.arrows[i],
                alpha: { from: 0, to: 1 },
                duration: 800,
                ease: 'Sine.easeInOut',
                repeat: -1,
                yoyo: true,
                delay: g.arrows[i].delay
            });
        }
    }

    function resetGame() {
        g.player.body.reset(g.player.x, g.player.y);
        g.isGameReady = false;
        setTimeout(function () {
            reSpawnPlayer();
        }, 1500);
    }

    function reSpawnPlayer() {
        g.player.setPosition(g.spawn.x, g.height/3);
        g.player.setAngle(35);
        createTooltipAnimation();
        g.this.tweens.add({
            targets: g.player,
            alpha: {from: 0, to: 1.5 },
            y: { from: 600, to: g.spawn.y },
            onComplete: function () {
                g.isGameReady = true;
            },
            duration: 1500,
            ease: 'Bounce.Out',
            repeat: 0,

        });
    }

    $(window).on('triare.game.go', function () {
        reSpawnPlayer();
    });

    function updatePlayerTrackers() {
        var point1 = g.player.getTopRight();
        var point2 = g.player.getBottomRight();

        g.playerTracker1.setPosition(point1.x, point1.y);
        g.playerTracker2.setPosition(point2.x, point2.y);
    }

    function getDistanceToPlayer(targetX, targetY) {
        targetX = targetX || g.target.x;
        targetY = targetY || g.target.y;

        return Phaser.Math.Distance.Between(g.player.x, g.player.y, targetX, targetY);
    }

    function getAngle() {
        var angle = Phaser.Math.Angle.Between(g.player.x, g.player.y, g.target.x, g.target.y);
        return angle;
    }

    function initMap() {
        var image = g.this.add.image(g.width / 2, g.height / 2, 'map');
        var scaleX = (g.width) / image.width;
        var scaleY = (g.height) / image.height;
        var scale = Math.max(scaleX, scaleY);

        image.setScale(scale).setScrollFactor(0);
        initObstacles();
    }

    function initObstacles() {
        g.finishLine = new Phaser.Geom.Rectangle(g.width * 0.67, g.height * 0.81, 200, 100);

        var linesJSON = g.this.cache.json.get('mapLines');
        g.polygon = parsePolygon(linesJSON);

        //g.debug.fillStyle(0x00aa00);
        //g.debug.fillRect(g.finishLine.x, g.finishLine.y, g.finishLine.width, g.finishLine.height)
        //g.debug.fillPoints(g.polygon.points, true);
    }

    function parsePolygon(polygon) {
        var points = [];
        for (var i = 0; i < polygon.length; i++) {
            points.push(polygon[i].x1 * (1.365 * 2), polygon[i].y1 * (1.35 * 2) + 10);
            points.push(polygon[i].x2 * (1.365 * 2), polygon[i].y2 * (1.35 * 2) + 10);
        }
        return new Phaser.Geom.Polygon(points);
    }

    var element = $("#html-content-holder"); // global variable
    var slowElement = $("#slow-html-content-holder");
    var firstElement = $("#first-html-content-holder");
    var getCanvas; // global variable

    function saveCanvas (){
        var imgageData = getCanvas.toDataURL("image/png");
        // Now browser starts downloading it instead of just showing it
        var newData = imgageData.replace(/^data:image\/jpg/, "data:application/octet-stream");
        $("#btn-Convert-Html2Image").attr("download", "result.jpg").attr("href", newData);
    }
    function winGame() {
        var slowestResult = parseInt(Slowest_racer.slowest_result);
        var fastestResult = parseInt(Fastest_racer.fastest_result);
        // console.log(slowestResult);

        if (g.timer.currentLapTime < fastestResult) {
            // result for number one
            Fastest_racer.fastest_result = g.timer.currentLapTime;

            fastestResult = parseInt(Fastest_racer.fastest_result);
            // console.log(fastestResult);

            $(".number-one-popup-wrap").fadeIn().css("display", "flex");
            let time = $("#timer").text();
            let newTimer = $(".update-timer-js").text(time);
            $(".save-button-win-1").on("click", function () {
                let firstUserName = $("#top-1").val();
                if (( firstUserName !== "" ) && ( !$.isNumeric(firstUserName) ) && (firstUserName.replace(/\s/g, '').length)){
                    $(".forName").html(firstUserName);
                    $(".result-wrap").hide();
                    $(".partner").show();
                    $(".close-game-popup").hide();
                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        data: {
                            action: "triare_race_game",
                            args: {
                                "name": firstUserName,
                                "time": g.timer.currentLapTime,
                                "slowest": Slowest_racer.slowest_result,
                            },
                        },
                        type: "POST",
                        success: function (data) {
                            let parsedData = JSON.parse(data).data;

                            if(parsedData !== null) {
                                $(".rating-table").html(parsedData);
                            }
                        },
                        error: () => {
                            console.log("error")
                        },
                    });
                    var canvasImage = function createCanvas() {
                        html2canvas(firstElement, {
                            onrendered: function (canvas) {
                                $("#previewImage").append(canvas);
                                getCanvas = canvas;

                            }
                        });

                    };
                    setTimeout(canvasImage,100);

                    setTimeout( function () {
                        saveCanvas();
                    },2000);
                    setTimeout( function () {
                        $("#btn-Convert-Html2Image")[0].click();
                    },3500);
                    setTimeout(function () {
                        $(".play-button-win-1").fadeIn();
                        $(".close-game-popup").fadeIn()
                    }, 2600);
                    $(".save-button-win-1").unbind("click")
                } else {
                    $("#top-1").css("border", "1px solid red")
                }
            });
        } else if (g.timer.currentLapTime < slowestResult) {
            // console.log("nice, save to results");
            // console.log(g.timer.currentLapTime + "top 10");
            $(".winner-popup-wrap").fadeIn().css("display", "flex");
            let time = $("#timer").text();
            let newTimer = $(".update-timer-js").text(time);

            //result 10 ten
            $(".save-button-win").on("click", function () {
                let winnerName = $("#top-10").val();

                if (( winnerName !== "" ) && ( !$.isNumeric(winnerName) ) && (winnerName.replace(/\s/g, '').length)){
                    let userName = $("#top-10").val();
                    $(".forName").html(userName);
                    $(".result-wrap").hide();
                    $(".partner").show();
                    $(".close-game-popup").hide();
                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        data: {
                            action: "triare_race_game",
                            args: {
                                "name": userName,
                                "time": g.timer.currentLapTime,
                                "slowest": Slowest_racer.slowest_result,
                            },
                        },
                        type: "POST",
                        success: function (data) {
                            let parsedData = JSON.parse(data).data;

                            if(parsedData !== null) {
                                $(".rating-table").html(parsedData);
                            }
                        },
                        error: () => {
                            console.log("error")
                        },
                    });

                    var canvasImage = function createCanvas() {
                        html2canvas(element, {
                            onrendered: function (canvas) {
                                $("#previewImage").append(canvas);
                                getCanvas = canvas;

                            }
                        });

                    };
                    setTimeout(canvasImage,100);

                    setTimeout( function () {
                        saveCanvas();

                    },2000);

                    setTimeout( function () {
                        $("#btn-Convert-Html2Image")[0].click();
                    },3500);
                    setTimeout(function () {
                        $(".play-button-win").fadeIn();
                        $(".close-game-popup").fadeIn();
                    }, 2600);

                    $(".save-button-win").unbind("click")
                } else {
                    $("#top-10").css("border", "1px solid red");
                }
            });
        }
        else {
            let randomSlowPhraseNumber = Math.floor(Math.random() * slowPhrases.length);
            let randomSlowPhrase = slowPhrases[randomSlowPhraseNumber];

            $(".slow-title").html(randomSlowPhrase["slow_title"]);
            $(".slow-subtitle").html(randomSlowPhrase["slow_subtitle"]);
            $(".slow-popup-wrap").fadeIn().css("display", "flex");
            let time = $("#timer").text();
            let newTimer = $(".update-timer-js").text(time);
            $(".save-button").on("click", function () {
                let newName = $("#name-slow").val();
                if (( newName !== "" ) && ( !$.isNumeric(newName) ) && (newName.replace(/\s/g, '').length)){
                    $(".forName").html(newName);
                    $(".result-wrap").hide();
                    $(".partner").show();
                    $(".close-game-popup").hide();

                    var canvasImage = function createCanvas() {
                        html2canvas(slowElement, {
                            onrendered: function (canvas) {
                                $("#previewImage").append(canvas);
                                getCanvas = canvas;
                            }
                        });

                    };
                    setTimeout(canvasImage,100);
                    setTimeout( function () {
                        saveCanvas();
                    },2000);
                    setTimeout( function () {
                        $("#btn-Convert-Html2Image")[0].click();
                    },3500);
                    setTimeout(function () {
                        $(".play-button").fadeIn();
                        $(".close-game-popup").fadeIn();
                    }, 2600);
                    $(".save-button").unbind("click")
                } else {
                    $("#name-slow").css("border", "1px solid red")
                }
            });
            // console.log("looool, too slow")
        }
    }
    function gameOver() {
        let randomLosePhraseNumber = Math.floor(Math.random() * losePhrases.length);
        let randomLoosePhrase = losePhrases[randomLosePhraseNumber];
        $(".lose-title").html(randomLoosePhrase["lose_title"]);
        $(".lose-subtitle").html(randomLoosePhrase["lose_subtitle"]);
        $(".lose-popup-wrap").fadeIn().css("display", "flex");
        let time = $("#timer").text();
        let newTimer = $(".update-timer-js").text(time);
    }
}

if ($(".game-container").length > 0) {
    gameInit();
}
});
