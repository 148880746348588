(function ($) {

    $(document).ready(function () {

        if($(".single-insight").length > 0) {

            //content navigation section
            let titleKey = 0;
            const headersInContent = $(".single-insight .content").find("h1, h2, h3, h4, h5, h6");

            if(headersInContent.length > 1) {
                headersInContent.each(function(){
                    if(!$(this).hasClass("ignored-title")) {
                        $(this).attr("data-title-key", titleKey);
                        //add items to content navigation list
                        $(".single-insight .content-navigation .content-navigation-list").append(
                            `<li><a href="javascript:void(0)" data-title-key-nav="${titleKey}">${$(this).text().replace(/[^\wа-яa-z0-9 іґїє.,&]/gi,'')}</a></li>`
                        );
                        titleKey++;
                    }
                });
                $('.content-navigation').slideDown();
            } else {
                $(".single-insight .content-navigation .content-navigation").addClass("hidden");
            }

            //do scroll when click on content navigation item
            $(".single-insight .content-navigation .content-navigation-list").on("click", "li a", function(){
                let headerHeight = $(".site-header").innerHeight();
                if($(window).width() > 1279) headerHeight = 0;
                const titleKey = $(this).attr("data-title-key-nav");

                $('html,body').animate({
                    scrollTop: $(".single-insight .content [data-title-key='" + titleKey + "']").offset().top - headerHeight
                }, 1000)
            });

        }
    });
})(jQuery);