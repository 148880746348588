import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

$(document).ready(function () {
    let videoBackground = $(".myVideo"),
        modal_div = $("#myModal");

    modal_div.on("shown.bs.modal", function (e) {
        videoBackground.trigger('pause');
        modal_div.find("video").trigger('play');
    });

    modal_div.on("hide.bs.modal", function (e) {
        videoBackground.trigger('play');
        modal_div.find("video").trigger('pause');
    });

    const content = $(".video-wrap, .img-wrap");
    const images = content.find(".video-reel-wrapper, .parallax-container");
    const container = content.find(".container");


    ScrollTrigger.create({
        trigger: content,
        endTrigger: content,
        start: 'top center',
        end: 'center center',
        scrub: true,

        animation: gsap.timeline()
            .to(images, {
                clipPath: "polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%)"
            })

    });

});