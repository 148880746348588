$(window).on('load', function () {
    let SearchInput = $('#search-insight-input');
    if (SearchInput.length > 0) {
        SearchInput.val('');
    }
});

(function ($) {

    $(document).ready(function () {

        let wpcf7Elm = $('.wpcf7');

        //lets talk form split name field
        // $(".your-name").focusout(function(){
        //     str = $(this).val();
        //     if(str){
        //         s = str.split(' '); //split string in name field after first space found.
        //         $(".first-name").val(s[0]); //s[0] value after the first space set to hidden field.
        //         $(".last-name").val(s[1]); //s[1] value after the first space set to hidden field.
        //     }
        // });

        $("[name*='your-phone'], [name*='phone']").on("keypress keyup blur", function (event) {
            $(this).val($(this).val().replace(/[^\d].+/, ""));
            if ((event.which < 48 || event.which > 57)) {
                event.preventDefault();
            }
        });

        $(document).on('change', '[name="multilinefile-cv[]"]', function () {
            let real_button = $(this).closest('form').find('input.wpcf7-submit[type="submit"]');
            if ($(this).val() !== '') {
                $('#multilinefile-cv-error').remove();
                real_button.prop("data-disabled", "enabled");
            }
        });

        $(".wpcf7 .pseudo-button button").on("click", function (e) {
            e.preventDefault();
            let real_button = $(this).closest('form').find('input.wpcf7-submit[type="submit"]');
            let files_input_required = $(this).closest('form').find('.wpcf7-validates-as-required.fileuploadfield');
            let files_wrap = $(this).closest('form').find('.uploadfieldtrick');
            let err_container = files_wrap.find(".fileupload-error");

            if (files_input_required.length > 0 && files_input_required.val() == '') {
                if (err_container.find('.wpcf7-not-valid-tip').length < 1) {
                    err_container.append('<label id="file-cv-error" class="wpcf7-not-valid-tip">This field is required.</label>');
                }
                real_button.prop("data-disabled", "disabled");
            } else {
                real_button.prop("data-disabled", "enabled");
            }
            console.log(real_button.prop("data-disabled"));
            if (real_button.prop("data-disabled") == "disabled") {
                return false;
            } else {
                real_button.click();
                return true;
            }
        });

        $(".wpcf7 form").submit(function () {
            $(this).find('input.wpcf7-submit[type="submit"]').prop("data-disabled", "disabled");
            $(this).find('.pseudo-button button').addClass("-sending");
            if ($(this).closest(".subscribe-form").length > 0 && !$(this).find('[type="submit"]').hasClass("-sending") && $(this).valid()) {
                $(this).find('[type="submit"]').addClass("-sending");
            }
        });

        wpcf7Elm.bind('wpcf7submit', function () {
            if($(this).hasClass('invalid')) {
                $(this).find('input.wpcf7-submit[type="submit"]').prop("data-disabled", "enabled");
            } else {
                $(this).find('input.wpcf7-submit[type="submit"]').prop("data-disabled", "disabled");
            }
        });

        wpcf7Elm.bind('wpcf7invalid', function () {
            $(this).find('input.wpcf7-submit[type="submit"]').prop("data-disabled", "enabled");
            $(this).find('.pseudo-button button').removeClass("-sending");
        });

        wpcf7Elm.bind('wpcf7mailfailed', function () {
            if ($(this).closest(".subscribe-form").length > 0) {
                $(this).find('[type="submit"]').removeClass("-sending");
            }
            $(this).find('input.wpcf7-submit[type="submit"]').prop("data-disabled", "enabled");
            $(this).find('.pseudo-button button').removeClass("-sending");
        });

        wpcf7Elm.bind('wpcf7mailsent', function () {
            if($(this).closest(".popup").data("event") !== '') {
                switch ($(this).closest(".popup").data("event")) {
                    case 'Contact_us_header_submit':
                        dataLayer.push({
                            'event': 'ContactUsHeaderSubmit'
                        });
                        break;
                    case 'Contact_us_menu_submit':
                        dataLayer.push({
                            'event': 'ContactUsMenuSubmit'
                        });
                        break;
                    case 'Get_price_menu_submit':
                        dataLayer.push({
                            'event': 'GetPriceMenuSubmit',
                        });
                        break;
                    case 'Contact_us_footer_submit':
                        dataLayer.push({
                            'event': 'gaTrigger',
                            'gaEventCategory': 'form_send',
                            'gaEventAction': 'Contact_us_footer_submit'
                        });
                        break;
                    case 'lets_talk_article_submit':
                        dataLayer.push({
                            'event': 'LetsTalkArticleSubmit'
                        });
                        break;
                }
            }
            if ($(this).closest(".subscribe-form").length > 0) {
                $(this).find('[type="submit"]').removeClass("-sending");
                $(this).find('form').append('<div class="custom-response-output">Thank You For Subscribing!</div>');
                dataLayer.push({'event': 'Subscribe_ok'});
                setTimeout(function () {
                    $('.custom-response-output').slideUp();
                }, 7000);
                return false;
            }
            let popup_div = $(this).closest('.popup');
            popup_div.addClass("-thnx");
            $.ajax({
                url: ajaxMeta.ajaxurl,
                type: "post",
                data: {
                    action: "get_thanks_text",
                },
                success: function (data) {
                    if (data) {
                        popup_div.find('.inner').html(data);
                    }
                }
            });
        });

        $(".popup .form-active .btn-pseudo").on("click", function (e) {
            e.preventDefault();
            let popup_box = $(this).closest('.popup');
            if (popup_box.hasClass('check_grecaptcha')) {
                popup_box.find('[type="submit"]').prop('disabled', false)
            }
            $(this).closest("form").find("[type='submit']").click();
        });

        $("textarea").each(function () {
            this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
        }).on("input", function () {
            this.style.height = "auto";
            this.style.height = (this.scrollHeight) + "px";
        });

        $(".uploadfieldtrick .uploadbrowsebutton").click(function () {
            $(this).closest('.uploadfieldtrick').find('.fileuploadfield').click();
        });
        $(".uploadfieldtrick .clear-fileupload").click(function () {
            $(this).closest('.uploadfieldtrick').find('.fileuploadfield').val('').change();
        });
        $('.uploadfieldtrick .fileuploadfield').change(function () {
            $(this).closest('.uploadfieldtrick').find('.uploadtextfield').html($(this).val().replace(/.*(\/|\\)/, ''));
            if($(this).val() !== '') {
                $(this).closest('form').find('input.wpcf7-submit[type="submit"]').prop("data-disabled", "enabled");
                $(this).closest('.uploadfieldtrick').find('.fileupload-error .wpcf7-not-valid-tip').remove();
            }
        });

    });

})(jQuery);