import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function initExpertiseSection() {
    if (window.matchMedia("(max-width: 992px)").matches) return false;

    document.querySelectorAll('.expertise').forEach((section) => {
        const images = section.querySelectorAll('.expertise-img');
        const nums = section.querySelectorAll('.expertise-num');
        const contents = section.querySelectorAll('.expertise-content');

        contents.forEach((content, index) => {
            const text = content.querySelector('.expertise-content__inner');
            const link = content.querySelectorAll('.expertise-content__links a');

            ScrollTrigger.create({
                trigger: content,
                endTrigger: content,
                start: 'top bottom',
                end: 'bottom bottom',
                scrub: true,

                animation: gsap.timeline()
                    .to(images[index], {
                        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                        ease: 'none'
                    })

                    .from(text, {
                        y: 250
                    }, '<0.1')
                    .from(link, {
                        y: 100,
                        stagger: 0.1
                    }, '<0.1')
            });

            let tl = gsap.timeline({
                // yes, we can add it to an entire timeline!
                scrollTrigger: {
                    trigger: content,
                    endTrigger: content,
                    start: 'top center',
                    end: 'bottom center',
                    scrub: true,

                    onEnter: function () {
                        gsap.timeline()
                            .to(nums, {
                                scale: 0.9,
                                opacity: 0.65,
                            }, '<')
                            .fromTo(nums[index], {
                                scale: 0.9,
                                opacity: 0.65,
                            }, {
                                scale: 1.3,
                                opacity: 1
                            }, '<')
                    },

                    onLeaveBack: function () {
                        if (index - 1 < 0) return false;

                        gsap.timeline()
                            .to(nums, {
                                scale: 0.9,
                                opacity: 0.65,
                            }, '<')
                            .fromTo(nums[index - 1], {
                                scale: 0.9,
                                opacity: 0.65,
                            }, {
                                scale: 1.3,
                                opacity: 1
                            }, '<')
                    },
                }
            });


        });

    });
}