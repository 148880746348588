(function ($) {

    $(document).ready(function () {
        $('.section_solutions .solutions .item h2').each(function () {
            let chars = $(this).html().split("");
            let trans_chars = [];
            chars.forEach(function (currentValue, index, array) {
                let num = index * 10;
                trans_chars.push('<span style="transform: translateX(' + num + 'px)">' + currentValue + '</span>')
            });
            $(this).html(trans_chars.join(''));
        });
        checkSolutions();
        $(window).on('scroll', function () {
            checkSolutions();
        });

    });
    function checkSolutions() {
        if ($('.section_solutions .solutions .item').length > 0) {
            $('.section_solutions .solutions .item').each(function () {
                $(this).isInViewport(function (status) {
                    if (status === 'entered' && !$(this).hasClass('-on-screen')) {
                        $(this).addClass('-on-screen');
                    }
                }, 100)
            });
        }
    }

})(jQuery);