(function ($) {

    $(document).ready(function () {

        let parent = $(".position-ajax");
        let hidden_input = $("input[name='position-value']");

        $("body").delegate(".position-ajax .item", "click", function () {
            if (!$(this).hasClass("-active")) {
                let this_value = $(this).data("job");

                $(".position-ajax .item").removeClass("-active");
                $(this).addClass("-active");
                hidden_input.val(this_value);
            }
        });

        $.ajax({
            url: ajaxMeta.ajaxurl,
            type: "post",
            data: {
                action: "get_positions_ajax",
            },
            success: function (data) {
                if (data.result) {
                    parent.append(data.result);
                }
                if (data.current && parent.find("[data-job='" + data.current + "']").length > 0) {
                    parent.find("[data-job='" + data.current + "']").addClass("-active");
                    hidden_input.val(data.current);
                } else {
                    parent.find("[data-job='other']").addClass("-active");
                    hidden_input.val('other');
                }
            }
        });

    });

})(jQuery);