import {gsap} from "gsap";

(function ($) {

    $('.slider-move-logotypes').liMarquee({
        scrollStop: false,
        circular: true,
        startShow: true,
        hoverStop: false,
        dragStart: function () {
            gsap.to('.cb-cursor', {
                scale: .75,
                duration: 0.3
            });
        },
        dragStop: function () {
            gsap.to('.cb-cursor', {
                scale: 1,
                duration: 0.3
            });
        }
    });

})(jQuery);
