import Swiper, { Autoplay } from 'swiper';

(function ($) {

    let insights = new Swiper(".swiper-insights", {
        modules: [Autoplay],
        loop: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false
        },
        slidesPerView: 1,
        spaceBetween: 0,
        speed: 1000,
        grabCursor: true,
        touchStartPreventDefault: false,
        breakpoints: {
            320: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 2
            },
            1400: {
                slidesPerView: 3
            }
        }
    });
})(jQuery);