import simpleParallax from 'simple-parallax-js';

(function ($) {

    $(document).ready(function () {
        let page = 1;
        let range = 12;
        let loading = false;

        let image = document.querySelectorAll('.case-thumbnail');
        let instance = new simpleParallax(image, {
            delay: .6,
            transition: 'cubic-bezier(0,0,0,1)',
            customWrapper: '.parallax-container',
        });

        $(".section_case_studies .load-more-btn").on("click", function () {

            if (!loading) {
                loading = true;
                let parent = $(this).closest(".section_case_studies").find(".case-studies");
                let section = $(this).closest(".section_case_studies");
                let projectCat = [];
                let target = section.find(".load-more-box");
                section.find("li.active[data-taxonomy]").each(function () {
                    projectCat.push($(this).data("taxonomy"));
                });

                if (projectCat.length > 0) {
                    target.addClass("-loading");

                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        type: "post",
                        data: {
                            action: "more_projects_ajax",
                            offset: (page * range),
                            range: range,
                            projectCat: projectCat,
                        },
                        success: function (data) {
                            if(data.result) {
                                page++;

                                loading = false;
                                parent.append(data.result);
                                image = document.querySelectorAll('.case-thumbnail');
                                instance = new simpleParallax(image, {
                                    delay: .6,
                                    transition: 'cubic-bezier(0,0,0,1)',
                                    customWrapper: '.parallax-container',
                                });
                                if(data.count <= range) {
                                    section.find(".load-more-box").addClass("-hidden");
                                } else {
                                    section.find(".load-more-box").removeClass("-hidden");
                                }
                                target.removeClass("-loading");
                            } else {
                                loading = false;
                                target.removeClass("-loading");
                                section.find(".load-more-box").addClass("-hidden");
                            }
                        }
                    });
                }
            }
        });
        $(".section_case_studies .units-categories .show_more_categories").on("click", function () {
            //detect show more categories button
            if ($(this).hasClass("show-more")) {
                $(this).removeClass("show-more").addClass("show-less").html("<span>Less</span>");
                $(this).hide().fadeIn(800);
                $(this).closest(".units-categories").removeClass("-hidden");
            } else if ($(this).hasClass("show-less")) {
                $(this).removeClass("show-less").addClass("show-more").html("<span>More</span>");
                $(this).hide().fadeIn(800);
                $(this).closest(".units-categories").addClass("-hidden");
            }
        });
        $(".section_case_studies .show-categories").on("click", function () {
            //detect show more categories button
            if ($(this).hasClass("show-more")) {
                $(this).removeClass("show-more").addClass("show-less");
                $(this).closest(".section_case_studies").find(".units-categories.-desk").removeClass("-hidden-mob");
            } else if ($(this).hasClass("show-less")) {
                $(this).removeClass("show-less").addClass("show-more");
                $(this).closest(".section_case_studies").find(".units-categories.-desk").addClass("-hidden-mob");
            }
        });
    });

})(jQuery);