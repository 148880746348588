export function handleUTMSource() {
    // Function to extract parameter from URL
    function getParameterByName(name) {
        let value = "";

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has(name)) {
            value = urlParams.get(name);
        } else {
            const cookies = document.cookie.split(';');
            cookies.forEach(cookie => {
                const [cookieName, cookieValue] = cookie.split('=').map(c => c.trim());
                if (cookieName === name) {
                    value = cookieValue;
                }
            });
        }

        return value;
    }

    // Function to set UTM source, medium, and campaign values in localStorage and hidden form fields
    const setUTMParameters = () => {
        const utmSource = getParameterByName('utm_source');
        const utmMedium = getParameterByName('utm_medium');
        const utmCampaign = getParameterByName('utm_campaign');
        const utmElements = document.querySelectorAll('form input[type="hidden"]');

        const hours = 700;
        if (utmSource) {
            utmElements.forEach(element => {
                if (element.name === 'utm_source') {
                    element.value = utmSource;
                } else if (element.name === 'utm_medium') {
                    element.value = utmMedium;
                } else if (element.name === 'utm_campaign') {
                    element.value = utmCampaign;
                }
            });

            document.cookie = `utm_source=${utmSource}; expires=${getCookieExpirationTime(hours)}; path=/`;
            document.cookie = `utm_medium=${utmMedium}; expires=${getCookieExpirationTime(hours)}; path=/`;
            document.cookie = `utm_campaign=${utmCampaign}; expires=${getCookieExpirationTime(hours)}; path=/`;
        }
    }

    // Function to calculate cookie expiration time
    function getCookieExpirationTime(hours) {
        const currentTime = new Date();
        const expirationTime = new Date(currentTime.getTime() + hours * 60 * 60 * 1000);
        return expirationTime.toUTCString();
    }

    // Call setUTMParameters when DOMContentLoaded event fires
    document.addEventListener('DOMContentLoaded', setUTMParameters);
}