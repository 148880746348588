import simpleParallax from 'simple-parallax-js';

(function ($) {

    $(".units-categories a").each(function() {
        if (this.href == window.location.href) {
            $(this).closest('li').addClass("active");
        }
    });

    $(document).ready(function () {
        let page = 1;
        let range = 12;
        let loading = false;

        let image = document.querySelectorAll('.case-thumbnail');
        let instance = new simpleParallax(image, {
            delay: .6,
            transition: 'cubic-bezier(0,0,0,1)',
            customWrapper: '.parallax-container',
        });

        $(".single-project-category .load-more-btn").on("click", function () {

            if (!loading) {
                loading = true;
                let parent = $(this).closest(".single-project-category").find(".case-studies");
                let section = $(this).closest(".single-project-category");
                let projectCatPage = [];
                let dataCategory = $(this).closest(".single-project-category").data("cat-page");
                let target = section.find(".load-more-box");
                let searchVal = '';
                if (dataCategory) {
                    projectCatPage.push(dataCategory);
                }

                if (projectCatPage.length > 0) {
                    target.addClass("-loading");

                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        type: "post",
                        data: {
                            action: "more_projects_ajax",
                            offset: (page * range),
                            range: range,
                            projectCat: projectCatPage,
                            s: '',
                        },
                        success: function (data) {
                            if (data.result) {
                                page++;

                                loading = false;
                                parent.append(data.result);
                                image = document.querySelectorAll('.case-thumbnail');
                                instance = new simpleParallax(image, {
                                    delay: .6,
                                    transition: 'cubic-bezier(0,0,0,1)',
                                    customWrapper: '.parallax-container',
                                });
                                if (data.count <= range) {
                                    section.find(".load-more-box").addClass("-hidden");
                                } else {
                                    section.find(".load-more-box").removeClass("-hidden");
                                }
                                target.removeClass("-loading");
                            } else {
                                loading = false;
                                target.removeClass("-loading");
                            }
                        }
                    });
                } else {
                    target.addClass("-loading");

                    $.ajax({
                        url: ajaxMeta.ajaxurl,
                        type: "post",
                        data: {
                            action: "more_projects_ajax",
                            offset: (page * range),
                            range: range,
                            projectCat: ["all"],
                            s: searchVal,
                        },
                        success: function (data) {
                            if (data.result) {
                                page++;

                                loading = false;
                                parent.append(data.result);
                                image = document.querySelectorAll('.case-thumbnail');
                                instance = new simpleParallax(image, {
                                    delay: .6,
                                    transition: 'cubic-bezier(0,0,0,1)',
                                    customWrapper: '.parallax-container',
                                });
                                if (data.count <= range) {
                                    section.find(".load-more-box").addClass("-hidden");
                                } else {
                                    section.find(".load-more-box").removeClass("-hidden");
                                }
                                target.removeClass("-loading");
                            } else {
                                loading = false;
                                target.removeClass("-loading");
                            }
                        }
                    });
                }
            }
        });
    });

})(jQuery);